import * as React from "react";
import PageLayout from "../../components/page-layout";
import Navbar from "../../components/navbar";

const HitsPage = () => {
  return (
    <PageLayout title="hits | grac">
      <body id="hits-page" className="page">
        <div className="center-container">
          <Navbar/>

          {/* INTRO */}
          <div className="page-block">
            <h1 className="page-title">
              <span style={{color: "var(--pink)"}}>h</span>
              <span style={{color: "var(--yellow)"}}>i</span>
              <span style={{color: "var(--green)"}}>t</span>
              <span style={{color: "var(--darkblue)"}}>s</span>
            </h1>
            <br/>
            <h3>jan 22 2023</h3>
            <img style={{height: "20px"}} src="https://hitwebcounter.com/counter/counter.php?page=8159909&style=0007&nbdigits=6&type=ip&initCount=0" />
            <br/>
            {/* <h3>oct 17 2022</h3>
            <img style={{height: "20px"}} src="https://hitwebcounter.com/counter/counter.php?page=8053080&style=0007&nbdigits=5&type=ip&initCount=0" />
            <br/> */}
            <h3>total</h3>
            <img style={{height: "20px"}} src="https://hitwebcounter.com/counter/counter.php?page=7994736&style=0007&nbdigits=6&type=ip&initCount=0"/> 
             {/* <img style={{height: "20px"}} src="https://hitwebcounter.com/counter/counter.php?page=7973421&style=0007&nbdigits=6&type=ip&initCount=0"/> */}
          
          </div>

        </div>
      </body>
    </PageLayout>
  );
};

export default HitsPage;
